import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import projectStyles from '../style.module.css'
import styles from './not-found.module.css'

const NotFound = () => {
  return (
    <div className={styles['container']}>
      <Helmet>
        <title>404 - Not Found - New Project2</title>
        <meta property="og:title" content="404 - Not Found - New Project2" />
      </Helmet>
      <div className={styles['Hero']}>
        <h1 className={styles['text']}>404 - Not Found</h1>
        <div className={styles['BtnGroup']}>
          <Link
            to="/"
            className={` ${styles['navlink']} ${projectStyles['button']} `}
          >
            <span>
              <span>Home</span>
              <span></span>
            </span>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default NotFound
