import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import styles from './navigation-links.module.css'

const NavigationLinks = (props) => {
  return (
    <nav className={` ${styles['Nav']} ${styles[props.rootClassName]} `}>
      <Link to="/" className={styles['navlink']}>
        {props.text}
      </Link>
      <span className={styles['text']}>{props.text1}</span>
      <span className={styles['text1']}>{props.text2}</span>
      <span id="tesy" className={styles['text2']}>
        {props.text3}
      </span>
      <a
        href="https://forms.gle/iNVdHdRXfFHsTNf26"
        target="_blank"
        rel="noreferrer noopener"
        className={styles['link']}
      >
        {props.text4}
      </a>
    </nav>
  )
}

NavigationLinks.defaultProps = {
  text: 'Home',
  text3: 'Roadmap',
  text2: 'Membership',
  text4: 'The Lounge - Signup',
  text1: 'About Us',
  rootClassName: '',
}

NavigationLinks.propTypes = {
  text: PropTypes.string,
  text3: PropTypes.string,
  text2: PropTypes.string,
  text4: PropTypes.string,
  text1: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default NavigationLinks
